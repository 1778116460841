<template>
  <div>
    <Warning
      v-for="(warning, index) in warnings"
      :key="'warning' + index"
      class="mb-2"
      :message="warning"
    />
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      warnings: [],
      checkFunctions: [
        this.assignedContainerDiffrentThanOrdered,
        this.reportedDebrisTypeDiffrentThanSet
      ]
    }
  },
  watch: {
    order: {
      handler() {
        this.warnings = []
        this.checkFunctions.forEach((func) => {
          const warning = func()
          if (warning) this.warnings.push(warning)
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    assignedContainerDiffrentThanOrdered() {
      if (this.order?.anyContainer) return null
      const assignedContainer = this.order?.assignedContainerTypeId
      const orderedContainer = this.order?.containerTypeId?.id || this.order?.containerTypeId
      if (orderedContainer && assignedContainer) {
        return assignedContainer !== orderedContainer
          ? 'Podstawiono inny rozmiar kontenera niż był zamówiony'
          : null
      } return null
    },
    reportedDebrisTypeDiffrentThanSet() {
      const reportedDebrisType = this.order?.reportedDebrisTypeId
      const debrisType = this.order?.debrisTypeId
      if (reportedDebrisType && debrisType) {
        return reportedDebrisType !== debrisType
          ? 'Wybrany typ odpadu jest inny niż raportowany'
          : null
      } return null
    }
  }
}
</script>
